import { Splide } from "@splidejs/splide";
import { Grid } from "@splidejs/splide-extension-grid";
// JavaScript code for the custom web component
class HeroSlider extends HTMLElement {
    constructor(args = { grid: false, splitLast: false, destroyMobile: false, destroyDesktop: false, mobileArrows: false, desktopArrows: false, interval: 6630 }) {
      super();
      this.split = this.hasAttribute('splitLast') || args.splitLast;
      this.grid = this.hasAttribute('split') || args.grid;
      this.destroyMobile = this.hasAttribute('destroyMobile') || args.destroyMobile;
      this.destroyDesktop = this.hasAttribute('nodesk') || args.destroyDesktop;
      this.mobileArrows = this.hasAttribute('mobileArrows') || args.mobileArrows;
      this.desktopArrows = this.hasAttribute('desktopArrows') || args.desktopArrows;
      this.interval = parseInt(this.getAttribute('interval')) || args.interval;

      console.log(this.interval)

      this.dimensions = this.split ? [[1,1],[1,2]] : [[1,2],[1,1]];
      this.gridConfig = this.grid ? { dimensions : this.dimensions } : false;
    }
  
    connectedCallback() {
     new Splide(this, {
        grid       : this.gridConfig,
        perPage    : 1,
        type   : 'loop',
        autoplay : false,
        arrows : this.desktopArrows,
        interval : '500ms',
        pagination : false,
        lazyLoad : 'nearby',
        preloadPages: 3,
        destroy: this.destroyDesktop,
        breakpoints: {
          1024 : {
            grid : false,
            autoplay : true,
            arrows: this.mobileArrows,
            interval : this.interval,
            destroy: this.destroyMobile
            
          }
        }
      }).mount( { Grid });
    }
  
}
  
  customElements.define('hero-slider', HeroSlider);
  